<template>
  <div class="races-events-statistics-a">
    <div class="statistic-area">
      <div class="racer-labels">
        <RacerLabelTerminal
          v-for="(racer, index) in $attrs.racers"
          :ordinalNumber="racer"
          :color="$attrs.colors[index]"
          :height="getlabelHeight()"
          orientation="right"
          :key="index">
        </RacerLabelTerminal>
      </div>
      <div class="racers-wrapper">
        <div class="racers-row"
             v-for="racer in $attrs.racers"
             :key="racer">
          <div class="position"
               v-for="(value, index) in $attrs.statsData[racer]"
               :key="index"
               :class="{'first' : value == '1'}">
            {{ positionLabel(value) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RacesEventsStatisticsA from '@/components/RacesEventsStatisticsA';
import RacerLabelTerminal from '@/components/Terminal/RacerLabelTerminal';

export default {
  name: 'RacesEventsStatisticsATerminal',
  extends: RacesEventsStatisticsA,
  components: {
    RacerLabelTerminal,
  },
  methods: {
    getlabelHeight() {
      if (window.innerWidth < 1900) {
        return 54;
      }
      return 72;
    },
  },
};
</script>

<style lang="scss" scoped>
  .races-events-statistics-a {
    .statistic-area {
      display: flex;
      padding: 16px 8px;
      background-color: var(--card);
      .racer-labels {
        width: 20%;
        div {
          margin: 2px 0;
        }
      }
      .racers-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .racers-row {
          display: flex;
          height: 54px;
          margin: 1px;
        }
        .position {
          flex-grow: 1;
          background-color: var(--button);
          color: rgba(#ffffff, 0.3);
          color: var(--text-primary-1);
          font-size: 22px;
          text-align: center;
          line-height: 54px;
          height: 100%;
          border-radius: 2px;
          margin: 1px;
          width: 100%;
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          &.first {
            background-color: var(--button);
            color: var(--text-primary-1);
          }
        }
      }
    }
  }
  @media all and (min-width: 1880px) {
    .races-events-statistics-a {
      .statistic-area {
        .racers-wrapper {
          .racers-row {
            height: 72px;
          }
          .position {
            font-size: 28px;
            line-height: 72px;
            margin: 1px;
          }
        }
      }
    }
  }
</style>
